import React from "react";
import { useEffect, useState } from "react";

import img from "../../assets/Homepage-Banner_R1.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import img1 from "../../assets/1.png";
import img2 from "../../assets/2.png";
import img3 from "../../assets/Homepage-Banner_R1.jpg";

export function HomeHero(props) {
  const [photo, setPhoto] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      change();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [photo]);

  const change = () => {
    if (photo === 3) {
      setPhoto(1);
      return;
    }

    setPhoto((prev) => prev + 1);
  };

  const returnPhotoURL = () => {
    switch (photo) {
      case 1:
        return img1;
      case 2:
        return img2;
      case 3:
        return img3;
    }
  };

  return (
    <section
      className="homeHero"
      style={{ backgroundImage: `url(${returnPhotoURL()})` }}
    >
      <div>
        <h1 className="largeHeadline white">
          Tryton Tool <br />
          Services
        </h1>
        <p className="smallDescription white">People. Products. Performance.</p>
      </div>
    </section>
  );
}
